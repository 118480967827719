import { NgModule } from '@angular/core';
import { DxGanttModule } from 'devextreme-angular/ui/gantt';
import { DxBarGaugeModule } from 'devextreme-angular/ui/bar-gauge';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCalendarModule } from 'devextreme-angular/ui/calendar';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDiagramModule } from 'devextreme-angular/ui/diagram';
import { DxDraggableModule } from 'devextreme-angular/ui/draggable';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxGalleryModule } from 'devextreme-angular/ui/gallery';
import { DxMapModule } from 'devextreme-angular/ui/map';
import {
  DxoAppointmentDraggingModule,
  DxoValueAxisModule,
} from 'devextreme-angular/ui/nested';
import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { DxHtmlEditorModule } from 'devextreme-angular/ui/html-editor';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import config from 'devextreme/core/config';
import { licenseKey } from '../devextreme-license';

config({ licenseKey });

@NgModule({
  declarations: [],
  imports: [
    DxDataGridModule,
    DxTreeListModule,
    DxGanttModule,
    DxChartModule,
    DxBarGaugeModule,
    DxMapModule,
    DxSelectBoxModule,
    DxSchedulerModule,
    DxScrollViewModule,
    DxFormModule,
    DxTabPanelModule,
    DxDraggableModule,
    DxCalendarModule,
    DxFileUploaderModule,
    DxContextMenuModule,
    DxDrawerModule,
    DxButtonModule,
    DxGalleryModule,
    DxDropDownBoxModule,
    DxoValueAxisModule,
    DxPieChartModule,
    DxDropDownButtonModule,
    DxDiagramModule,
    DxHtmlEditorModule,
    DxTooltipModule,
    DxPopupModule,
    DxoAppointmentDraggingModule,
  ],
  exports: [
    DxDataGridModule,
    DxTreeListModule,
    DxGanttModule,
    DxChartModule,
    DxBarGaugeModule,
    DxMapModule,
    DxSelectBoxModule,
    DxSchedulerModule,
    DxScrollViewModule,
    DxFormModule,
    DxTabPanelModule,
    DxDraggableModule,
    DxCalendarModule,
    DxDrawerModule,
    DxContextMenuModule,
    DxButtonModule,
    DxGalleryModule,
    DxDropDownBoxModule,
    DxPieChartModule,
    DxoValueAxisModule,
    DxDropDownButtonModule,
    DxDiagramModule,
    DxHtmlEditorModule,
    DxTooltipModule,
    DxPopupModule,
    DxoAppointmentDraggingModule,
  ],
})
export class DxModule {}
